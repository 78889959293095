
import MBaseModal from "@/components/MBaseModal.vue";
import { Options, Vue } from "vue-class-component";
import MTextField from "@/components/form/MTextField.vue";
import MLinkList from "@/components/form/MLinkList.vue";
import MButton from "@/components/MButton.vue";
import { RelatedLink } from "@/entities/submission_master";
import { createServiceTopic } from "@/api/service_topic";
import store from "@/store";
import { convertToUnixtimeFromDate } from "@/utils/date";
import dayjs from "dayjs";

@Options({
  components: {
    MBaseModal,
    MTextField,
    MButton,
    MLinkList
  },
  emits: ["close"],
  props: {}
})
export default class ServiceTopicAddModal extends Vue {
  name = "";
  date = dayjs().format("YYYY-MM-DD");
  links: RelatedLink[] = [];

  get editingLinks() {
    return this.links.length > 0 ? this.links : [{ label: "", url: "" }];
  }

  get validData() {
    // LinkList コンポーネントの仕様上 links は配列だが、実際リンクは 1 つのみ
    return !!(
      this.links.length === 1 &&
      this.links[0].label &&
      this.links[0].url
    );
  }
  async createServiceTopic() {
    if (!this.validData) return;
    store.commit("START_LOADING", "作成中...");
    try {
      await createServiceTopic({
        url: this.links[0].url,
        label: this.links[0].label,
        timestamp: convertToUnixtimeFromDate(this.date)
      });
      alert("新しいお知らせを登録しました。");
      this.$router.go(0);
    } catch (e) {
      alert("新しいお知らせの登録中にエラーが発生しました。");
      console.log(e);
    } finally {
      store.commit("END_LOADING");
    }
  }

  onUpdateLinks(next: RelatedLink[]) {
    this.links = next;
  }

  close() {
    this.$emit("close");
  }
}
