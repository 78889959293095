
import store from "@/store";
import MIcon from "@/components/MIcon.vue";
import MNavBar from "@/components/MNavBar.vue";
import MButton from "@/components/MButton.vue";
import MHeaderPanel from "@/components/MHeaderPanel.vue";
import ServiceTopicAddModal from "@/components/topic/ServiceTopicAddModal.vue";
import ServiceTopicEditModal from "@/components/topic/ServiceTopicEditModal.vue";
import { Options, Vue } from "vue-class-component";
import { State, Getter } from "@/store/helper";
import { ServiceTopic } from "@/entities/service_topic";
import dayjs from "dayjs";
import firebase from "firebase";
import { School, SchoolConfig } from "../entities/school";

type ServiceTopicDataToDisplay = {
  ref: firebase.firestore.DocumentReference;
  url: string;
  label: string;
  timestamp: number;
  date: string;
  isNew: boolean;
};

@Options({
  components: {
    MIcon,
    MNavBar,
    MButton,
    MHeaderPanel,
    ServiceTopicAddModal,
    ServiceTopicEditModal
  }
})
export default class Home extends Vue {
  @State("serviceTopics", "serviceTopic") serviceTopics!: ServiceTopic[];
  @State("serviceTopicsOfSchool", "serviceTopic")
  serviceTopicsOfSchool!: ServiceTopic[];
  @State("school") school!: School | null;
  @State("schoolConfig") schoolConfig!: SchoolConfig | null;
  @State("initialized", "serviceTopic") isServiceTopicsLoaded!: boolean;
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isServiceProvider") isServiceProvider!: boolean;
  @Getter("isExclusiveSchoolAiUser") isExclusiveSchoolAiUser!: boolean;
  addModalVisible = false;
  updateModalVisible = false;
  selectedServiceTopic: ServiceTopic | null = null;

  get schoolName() {
    return this.school?.data.name;
  }

  get serviceTopicsToDisplay(): ServiceTopicDataToDisplay[] {
    return this.serviceTopics.map(({ ref, data }) => {
      const d = dayjs.unix(data.timestamp).startOf("day");
      return {
        ...data,
        ref,
        date: d.format("YYYY/MM/DD"),
        isNew: d.add(7, "day").isAfter(dayjs().startOf("day")) // 7 日以内のお知らせかどうか
      };
    });
  }

  get serviceTopicsToDisplayOfSchool(): ServiceTopicDataToDisplay[] {
    return this.serviceTopicsOfSchool.map(({ ref, data }) => {
      const d = dayjs.unix(data.timestamp).startOf("day");
      return {
        ...data,
        ref,
        date: d.format("YYYY/MM/DD"),
        isNew: d.add(7, "day").isAfter(dayjs().startOf("day")) // 7 日以内のお知らせかどうか
      };
    });
  }

  startUpdatingServiceTopic(serviceTopic: ServiceTopicDataToDisplay) {
    let serviceTopics: ServiceTopic[];
    if (this.isAdmin) {
      serviceTopics = this.serviceTopics;
    } else if (this.isAdminOfSchool) {
      serviceTopics = this.serviceTopicsOfSchool;
    } else {
      serviceTopics = [];
    }
    this.selectedServiceTopic = serviceTopics.find(
      _ => _.ref.id === serviceTopic.ref.id
    )!;
    this.updateModalVisible = true;
  }

  async mounted() {
    await store.dispatch("serviceTopic/setup");
  }
}
