
import MBaseModal from "@/components/MBaseModal.vue";
import { Options, Vue } from "vue-class-component";
import MTextField from "@/components/form/MTextField.vue";
import MLinkList from "@/components/form/MLinkList.vue";
import MButton from "@/components/MButton.vue";
import { RelatedLink } from "@/entities/submission_master";
import { updateServiceTopic, deleteServiceTopic } from "@/api/service_topic";
import store from "@/store";
import { convertToUnixtimeFromDate } from "@/utils/date";
import dayjs from "dayjs";
import { ServiceTopic } from "@/entities/service_topic";

@Options({
  components: {
    MBaseModal,
    MTextField,
    MButton,
    MLinkList
  },
  emits: ["close"],
  props: {
    serviceTopic: Object
  }
})
export default class ServiceTopicEditModal extends Vue {
  name = "";
  date = dayjs().format("YYYY-MM-DD");
  links: RelatedLink[] = [];
  serviceTopic!: ServiceTopic;

  get validData() {
    // LinkList コンポーネントの仕様上 links は配列だが、実際リンクは 1 つのみ
    return !!(
      this.links.length === 1 &&
      this.links[0].label &&
      this.links[0].url
    );
  }
  async updateServiceTopic() {
    if (!this.validData) return;
    store.commit("START_LOADING", "更新中...");
    try {
      await updateServiceTopic(this.serviceTopic.ref, {
        url: this.links[0].url,
        label: this.links[0].label,
        timestamp: convertToUnixtimeFromDate(this.date)
      });
      alert("お知らせを更新しました。");
      this.$router.go(0);
    } catch (e) {
      alert("お知らせの更新中にエラーが発生しました。");
    } finally {
      store.commit("END_LOADING");
    }
  }

  async deleteServiceTopic() {
    store.commit("START_LOADING", "削除中...");
    try {
      await deleteServiceTopic(this.serviceTopic.ref);
      alert("お知らせを削除しました。");
      this.$router.go(0);
    } catch (e) {
      alert("お知らせの削除中にエラーが発生しました。");
    } finally {
      store.commit("END_LOADING");
    }
  }

  onUpdateLinks(next: RelatedLink[]) {
    this.links = next;
  }

  created() {
    this.date = dayjs
      .unix(this.serviceTopic.data.timestamp)
      .format("YYYY-MM-DD");
    if (this.serviceTopic.data.label && this.serviceTopic.data.url) {
      this.links = [
        { label: this.serviceTopic.data.label, url: this.serviceTopic.data.url }
      ];
    }
  }

  close() {
    this.$emit("close");
  }
}
